<template>
  <div class="kyc-prep">
    <p class="has-text-weight-bold is-size-4 mb-6">
      {{ $t("af:application.sign_prep.title") }}
    </p>
    <!--
    <p class="mb-6">
      {{ $t("af:application.sign_prep.subtitle") }}
    </p>-->

    <application-template template="SIGN_PREP"></application-template>
  </div>
</template>

<script>
export default {
  name: "ApplicationFlowKYCPreparation",
  components: {
    ApplicationTemplate: () => import("@/components/ApplicationTemplate.vue"),
  },
};
</script>

<style scoped lang="scss">
.kyc-prep::v-deep {
  .requirement {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 1rem 0;

    &__icon {
      height: 40px;
      width: 40px;
      border-radius: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(0, 182, 127, 0.45);
    }
  }
}
</style>
